<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <base-card>
        <v-card-text class="text-center">
          <div class="d-flex flex-column justify-space-between align-center mb-4">
            <v-img
              :width="200"
              src="@/assets/images/logo.svg"
            />
          </div>
          <div
            v-if="!requestSent"
            class=""
          >
            <h6 class="text--disabled font-weight-medium mb-10">
              {{ subtitle }}
            </h6>
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              :label="$t('general.password')"
              :counter="8"
              :error-messages="passwordErrors"
              @click:append="show = !show"
            />
            <v-text-field
              v-model="confirmPassword"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              name="input-10-2"
              :label="$t('general.confirmPassword')"
              :counter="8"
              :error-messages="confirmPasswordErrors"
              @click:append="show = !show"
            />
            <v-btn
              class="mt-4 mb-4"
              block
              color="primary"
              dark
              @click="setPassword"
            >
              {{$t('send')}}
            </v-btn>
          </div>
          <h6
            v-else
            class="text--disabled font-weight-medium mb-10"
          >
            {{ responseMessage }}
          </h6>
          <div class="">
            <v-btn
              text
              small
              color="primary"
              to="/"
            >
              {{$t('login')}}
            </v-btn>
          </div>
        </v-card-text>
        <v-snackbar
          v-model="snackbar"
          top
          color="danger"
        >
          {{ messageSnackbar }}
          <template v-slot:action="{attrs}">
            <v-btn
              color=""
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </base-card>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import axios from 'axios'
  import { validationMixin } from 'vuelidate'
  import { required, minLength, sameAs } from 'vuelidate/lib/validators'
  import store from '../../../store'
  import { config } from '../../../data/config'
  
  const formatSpecial = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  const passwordPolicySpecial = (pass) => formatSpecial.test(pass)
  const passwordPolicyNumber = (pass) => /\d/.test(pass)
  const passwordPolicyUpper = (pass) => pass !== pass.toLowerCase()
  const passwordPolicyLower = (pass) => pass !== pass.toUpperCase()

  export default {
    name: 'RegisterUser',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Registrierung',
    },
    mixins: [validationMixin],
    data () {
      return {
        show: false,
        password: '',
        firstname: '',
        lastname: '',
        checkbox1: true,
        checkbox2: false,
        email: '',
        confirmPassword: '',
        loading: false,
        snackbar: false,
        messageSnackbar: '',
        requestSent: false,
        subtitle: '',
        responseMessage: '',
      }
    },

    methods: {
      ...mapActions(['signUserUp']),
      setPassword () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.snackbar = true
          return
        }
        axios.post(`${process.env.VUE_APP_SMT_API_URL}/user/password/change`, {
          i_UserID: this.$route.params.id,
          s_NewPassword: this.password,
          b_SendEmail: true,
        }, {
          headers: {
            'X-AUTH-TOKEN': this.$route.params.token,
          },
        })
          .then(response => {
            this.requestSent = true
            this.responseMessage = 'Passwort changed'
          })
          .catch(error => {
            this.snackbar = true
            if (error.response.status === 401) {
              this.messageSnackbar = 'Token incorrect'
            } else {
              this.messageSnackbar = 'Etwas ist schief gelaufen'
            }
          })
      },
      checkUserIdAndTokenSub () {
        const tokenDecodablePart = this.$route.params.token.split('.')[1]
        const decodedToken = JSON.parse(Buffer.from(tokenDecodablePart, 'base64').toString())
        if (parseInt(this.$route.params.id) !== decodedToken.sub) return false
        return true
      },
    },
    computed: {
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) {
          this.messageSnackbar = 'Password must be required'
          return errors
        }
        !this.$v.password.required && errors.push('Password is required')
        !this.$v.password.minLength && errors.push('Password must have minimum 10 characters')
        return errors
      },
      confirmPasswordErrors () {
        const errors = []
        if (!this.$v.confirmPassword.$dirty) {
          this.messageSnackbar = 'Password must be required'
          return errors
        }
        !this.$v.confirmPassword.required && errors.push('Confirm password')
        !this.$v.confirmPassword.sameAsPassword && errors.push('Passwords do not match')
        return errors
      },
    },
    validations: {
      password: {
        required,
        minLength: minLength(config.passwordConditions.minPasswordLength),
        passwordPolicySpecial,
        passwordPolicyNumber,
        passwordPolicyUpper,
        passwordPolicyLower
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
    mounted () {
      if (this.checkUserIdAndTokenSub()) {
        this.subtitle = 'Neues Passwort eingeben'
      } else {
        this.requestSent = true
        this.responseMessage = 'Token incorrect'
      }
    },
    beforeRouteEnter(to, from, next) {
      if (store.getters.isLoggedIn) { next('/') }
      else next()
    },
  }
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
